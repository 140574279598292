import React from 'react'
import PropTypes from 'prop-types'
import { ToastContainer } from "react-toastify"
import { parseUrlParams } from '../utils'
import { Link } from 'gatsby'
import { Navbar } from 'reactstrap'

import "react-toastify/dist/ReactToastify.css"
import 'bootstrap/dist/css/bootstrap.min.css'
import './layout.css'

export default class LayoutMinimal extends React.Component {
  static propTypes = {
    token: PropTypes.string, 
    user: PropTypes.object, 
    logout: PropTypes.func,
  }

  state = {
    isWebView: false,
  }

  componentDidMount() {
    // Show webview friendly interface when param d=rnwebview.
    // But with a safeguard. If we realize after Xs that the browser is no webview, back to normal

    const params = parseUrlParams(window.location.search);

    if (params.d === 'rnwebview') {
      this.setState({isWebView: true})

      // If in 5 seconds we don't get isWebView: true in window, back to normal
      setTimeout(() => {
        if (window.isWebView !== true) this.setState({isWebView: false})
      }, 5000)
    }


  }

  render() {

    const {token, user, children, logout} = this.props
    const {isWebView} = this.state

    return (
      <>
        {
          isWebView ? children
          : ( 
            <>
                <Navbar dark expand="lg" className="navigation">
                  <Link to="/" className="navbar-brand"> Cinetimes </Link>
                </Navbar>
                {children}
            </>
          )
        }
        <ToastContainer autoClose={8000} position="top-center" hideProgressBar pauseOnHover/>
      </>
    )
  }
} 
