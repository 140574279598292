import {url, getToken} from '../api'

export const getLikes = async (page = 1) => {
    const token = await getToken()
    const headers = {'content-type': 'application/json'}
    if (token !== null) {
        headers['Authorization'] = `Token ${token}`
    }
    const response = await fetch(`${url}/watchlist/?page=${page}`, {
      headers: headers,
      method: 'GET',
    })

    if (response.ok) {
      const result = await response.json()
      return result
    }
  
    const errMessage = await response.json()
    throw new Error(JSON.stringify(errMessage))  
}

export const addLike = async (movieId, like) => {
    const token = await getToken()
    const headers = {'content-type': 'application/json'}
    if (token !== null) {
        headers['Authorization'] = `Token ${token}`
    }
    const response = await fetch(`${url}/likes/`, {
        headers: headers,
        method: 'POST',
        body: JSON.stringify({
            'movie_id': movieId,
            like,
        }),
    })

    if (response.ok) {
        const result = await response.json()
        return result
    }

    const errMessage = await response.json()
    // Jsonify and parse later because error.messages will apply .toString()
    throw new Error(JSON.stringify(errMessage))       
}


export const deleteLike = async (movieId) => {
    const token = await getToken()
    const response = await fetch(`${url}/likes/`, {
        headers: {
            'content-type': 'application/json',
            'Authorization': `Token ${token}`,
        },
        method: 'DELETE',
        body: JSON.stringify({'movie_id': movieId}),
    })
    
    if (response.ok) {
        return true
    }
    const errMessage = await response.json()
    throw new Error(JSON.stringify(errMessage))  
}
