import React, {useState} from 'react';
import { addLike } from '../../apis/like';
import "./setup.css"

import poster from '../../images/mort.de.stalline.jpg'
import LayoutMinimal from '../../components/LayoutMinimal';
import { navigate } from 'gatsby';

export default function SetupRatings() {
    const [index, setIndex] = useState(0)
    const [likeCount, setLikeCount] = useState(0)
    const [like, setLike] = useState(false)
    const [dislike, setDisliked] = useState(false)
    const [pass, setPass] = useState(false)

    const onLike = () => {
        addLike(movies[index].id, true)
        if (index < movies.length - 1) setIndex(index+1)
        setLikeCount(likeCount+1)
      };
      const onDislike = () => {
        addLike(movies[index].id, false)
        if (index < movies.length - 1) setIndex(index+1)
    };
    const onPass = () => {
        if (index < movies.length - 1) setIndex(index+1)
    };
  
  return (
    <LayoutMinimal>
      <div className="text-center mb-5">
        {/* <img className="text-center mx-auto mt-3 rounded" width="80" height="80" src={logo}/> */}
        <div>
          <div className="container">
            <h1 className="mt-5 font-weight-bold"> Dites-nous ce que vous aimez </h1>
            <p className="mb-5" >
              Notez quelques films afin de reçevoir des recommandations pertinentes.
              <br/> 
              Cliquez sur "passer" si vous n'avez pas vu le film ou ne souhaitez pas le noter.
            </p>
          </div>

          <div className="d-flex justify-content-center flex-direction-column">
            <div style={{maxWidth:230}} >
                <h5 className="font-weight-bold">{movies[index].title}</h5>
                <img src={movies[index].image} className="bg-white rounded shadow-sm" width="220" alt="logo netflix"/>
            </div>
          </div>
          <div className="mx-auto mt-2 d-flex flex-nowrap" style={{maxWidth:220}}>
            <input type="submit" className="btn btn-md btn-success mr-1" value="👍" onClick={onLike}/>
            <input type="submit" className="btn btn-md btn-danger mr-1" value="👎" onClick={onDislike}/>
            <input type="submit" className="btn btn-md btn-secondary w-100" value="Passer" onClick={onPass}/>
          </div>

          <div className="mx-auto mt-2 d-flex flex-nowrap" style={{maxWidth:220}}>
              { likeCount >= 5 &&
                <button className="btn btn-md btn-dark  w-100" onClick={() => {
                  localStorage.removeItem('homepageSavedSlug')
                  localStorage.removeItem('homepageState')
                  localStorage.removeItem('homepageSavedDate')
                  navigate("/")
                }}> Aller sur le site </button>
              }
          </div>

        </div>
      </div>
    </LayoutMinimal>
  );
}

const movies = [
  {title: "Avatar", image: "https://img.cinetimes.org/img/cache/8e/ed/8eed7f33a5d6421fd2d8e5d349787512.jpg", id: 5722},
    {
        title: "Le Dernier Samouraï",
        image: "https://img.cinetimes.org/img/cache/8e/45/8e451fef2de259bc3c3ae43f3f78d519.jpg",
        id: 3128,
    },
    {
      title: "Harry Potter à l'école des sorciers",
      image: "https://img.cinetimes.org/img/cache/d2/7d/d27d4fc8a9043ef74179c08392b566c2.jpg",
      id: 71,
    },
    {title: "Les Visiteurs", image: "https://img.cinetimes.org/img/cache/6c/0b/6c0b9ec458f5a861d390f11fa645edd9.jpg", id: 337},
    {
      title: "Wonder Woman",
      image: "https://image.tmdb.org/t/p/w500//oomdTdke7dqffdDoDV1fFBV4fJY.jpg",
      id: 27473,
    },
    {
      title: "Autant en emporte le vent",
      image: "https://img.cinetimes.org/img/cache/68/d6/68d69fb7721b2f50f2e98f48ae09ff57.jpg",
      id: 369,
    },
    {
      title: "Cinquante nuances de Grey",
      image: "https://img.cinetimes.org/img/cache/af/79/af7981d6facd70e7b56983577066821f.jpg",
      id: 8286,
    },
    {
      title: "Les Indestructibles",
      image: "https://image.tmdb.org/t/p/w500//7uv6vgcHyIQ8KwpsqJKYwXKaEVH.jpg",
      id: 3619,
    },
    {
      title: "Inception",
      image: "https://img.cinetimes.org/img/cache/bf/fd/bffd9c42b049289b427ee8ad334983e9.jpg",
      id: 5964,
    },
    {
      title: "Danse avec les loups",
      image: "https://img.cinetimes.org/img/cache/31/d1/31d18c60aa71a94958a818f2117fb02a.jpg",
      id: 8790,
    },
    {
      title: "La reine des neiges",
      image: "https://image.tmdb.org/t/p/w500//nnQLuzh2H6tSk1U0Zyu0ju16FXU.jpg",
      id: 7490,
    },
    {title: "Star Wars, épisode I - La Menace fantôme", image: "https://img.cinetimes.org/img/cache/5f/4f/5f4f8d7ee687ceedc2f46667358519e7.jpg", id: 1201},
    {title: "Dumb and Dumber", image: "https://img.cinetimes.org/img/cache/48/53/4853b9eea1fea43e07d9964aebd07cc8.jpg", id: 131},
    { title: "Minority Report", image: "https://img.cinetimes.org/img/cache/72/de/72ded49cac6489f8ac7df16808f65cd2.jpg",id: 2461 },
    {
        title: "Intouchables",
        image: "https://img.cinetimes.org/img/cache/e8/e3/e8e3313bca8220a9d647be1068d1059d.jpg",
        id: 6726,
    },
    {
        title: "Astérix et Obélix : Mission Cléopâtre",
        image: "https://img.cinetimes.org/img/cache/13/21/132178fc27b50363df2848c4aa00c5da.jpg",
        id: 3915,
    },
    {
        title: "Le Dîner de cons",
        image: "https://image.tmdb.org/t/p/w500//ro1oAzuw7PtqcREGv5Q2nRctuRQ.jpg",
        id: 1238,
    },
    {title: "Peter Pan", image: "https://img.cinetimes.org/img/cache/6d/ea/6dea5aaef63e38375873c346d5153a71.jpg", id: 903},
    {
        title: "Bienvenue chez les Ch'tis",
        image: "https://img.cinetimes.org/img/cache/20/4a/204a0bfeda6474efc025b5d770f43840.jpg",
        id: 16152,
    },
    {
        title: "Le Loup de Wall Street",
        image: "https://img.cinetimes.org/img/cache/b7/f3/b7f3441b6fe0faec3e1ec41251fba83f.jpg",
        id: 7494,
    },
    {
        title: "La Passion du Christ",
        image: "https://image.tmdb.org/t/p/w500//1Iml5MpQhV2QyHHIZ4Rzm6IB7vD.jpg",
        id: 12522,
    },
    {
        title: "La Liste de Schindler",
        image: "https://img.cinetimes.org/img/cache/3d/7e/3d7e16df49f2646830fbef01289d34c7.jpg",
        id: 280,
    },
    {
        title: "Les Évadés",
        image: "https://img.cinetimes.org/img/cache/8f/5d/8f5d9757e2f4069a8c874c0efa8a4589.jpg",
        id: 170,
    },
    {
        title: "No Country for Old Men",
        image: "https://img.cinetimes.org/img/cache/c0/e7/c0e7b408fa005a4c95c9b8ad8c8d03a5.jpg",
        id: 4971,
    },
    {
        title: "Fargo",
        image: "https://image.tmdb.org/t/p/w500//pkuNNMtJ40h3l0eQIMM8CYHXJlQ.jpg",
        id: 8802,
    },
    {
        title: "La La Land",
        image: "https://image.tmdb.org/t/p/w500//5KIj6aioW1UtUT1IV0qqW5iZbNH.jpg",
        id: 27579,
    },
    {
        title: "Fight Club",
        image: "https://img.cinetimes.org/img/cache/b1/98/b198b19947425286d71e84ed61d83574.jpg",
        id: 1369,
    },
    {
        title: "Lady Bird",
        image: "https://image.tmdb.org/t/p/w500//gp3TcnurRFTOnWUZZj5QtG16m5d.jpg",
        id: 27911,
    },
    {
        title: "Venom",
        image: "https://image.tmdb.org/t/p/w500//vVusHIRlyyFVS42XnqZso2wGKr.jpg",
        id: 27531,
    },
    {
        title: "Inception",
        image: "https://img.cinetimes.org/img/cache/bf/fd/bffd9c42b049289b427ee8ad334983e9.jpg",
        id: 5964,
    },
    {
        title: "Memento",
        image: "https://img.cinetimes.org/img/cache/a4/c0/a4c0e78ed3ef1a4fc2ec62964d5246f9.jpg",
        id: 1946,
    },
    {title: "A Star Is Born", image: "https://image.tmdb.org/t/p/w500//uoq8DobPxciPEJ5EuBeqDt8av76.jpg", id: 27717},
    {title: "Vol au-dessus d'un nid de coucou", image: "https://img.cinetimes.org/img/cache/02/ad/02adf065ec0369964eccb28a0aca7a3f.jpg", id: 469},
    {title: "Inglourious Basterds", image: "https://img.cinetimes.org/img/cache/f0/5d/f05d40b943def57069475cb4b76533c5.jpg", id: 5450},
    {title: "Avant toi", image: "https://image.tmdb.org/t/p/w500//uNpiKNBkY2RV1uyYpDle9LAU0VH.jpg", id: 27917},
    {title: "Le train sifflera trois fois", image: "https://img.cinetimes.org/img/cache/4a/bb/4abbf9af2715dbd20e5f6690b1f11b0f.jpg", id: 525},
    {title: "Le Roi lion", image: "https://img.cinetimes.org/img/cache/ac/dd/acdde3d0406db0d36d9b7dcf4f0cb93e.jpg", id: 194},
    {title: "Le Roi Lion (Nouvelle Version)", image: "https://image.tmdb.org/t/p/w500//7QqLsDKe3myax9mfQf5EvJ2qk6u.jpg", id: 27411},
    {title: "12 Years a Slave", image: "https://img.cinetimes.org/img/cache/64/f0/64f061e861b13fc632829f7d49818f87.jpg", id: 7445},
    {title: "Captain Marvel", image: "https://img.cinetimes.org/img/cache/6d/ad/6dad5d45bd655ef753067a15bff3ec43.jpg", id: 7},
    {title: "Sans un bruit", image: "https://image.tmdb.org/t/p/w500//xlJCSHnR7sbaibX3OY97vEmsjxt.jpg", id: 27549},
    {title: "Matrix", image: "https://img.cinetimes.org/img/cache/07/db/07dbfb55750139dd34b0e68adec394c7.jpg", id: 1178},
    {title: "Pulp Fiction", image: "https://img.cinetimes.org/img/cache/d5/96/d596b2967a09c5b572be0f4ce7bb0c88.jpg", id: 162},
    {title: "Shutter Island", image: "https://img.cinetimes.org/img/cache/36/f4/36f48877f33f6318669f353526056651.jpg", id: 5798},
    {title: "Shining", image: "https://img.cinetimes.org/img/cache/be/a4/bea48e986b40c4ba97be1ce81d2c068c.jpg", id: 510},
    {title: "Will Hunting", image: "https://img.cinetimes.org/img/cache/a1/b8/a1b87dc6f8c2fc5d743c0ba53dfdf458.jpg", id: 705},
    {title: "The Big Lebowski", image: "https://img.cinetimes.org/img/cache/2d/58/2d58b35091725684d45f776a18030e84.jpg", id: 720},
    {title: "Toy Story", image: "https://img.cinetimes.org/img/cache/4f/31/4f319ff19f7737b21de2db545c0c4c55.jpg", id: 8435},
    {title: "Les temps modernes", image: "https://img.cinetimes.org/img/cache/1b/47/1b474fd88045a0986e3e0397a24ad628.jpg", id: 1576},
    {title: "Cinquante nuances de Grey", image: "https://img.cinetimes.org/img/cache/af/79/af7981d6facd70e7b56983577066821f.jpg", id: 8286},
    {title: "Le Kid", image: "https://img.cinetimes.org/img/cache/31/fe/31fe23c6cb7b9ce75814ac5a2f9e2426.jpg", id: 3},
    {title: "Get Out", image: "https://image.tmdb.org/t/p/w500//97WSXRs5TSCQPup6t9gxB1xkD47.jpg", id: 27587},
    {title: "Reservoir Dogs", image: "https://image.tmdb.org/t/p/w500//p61wwD0jWfgbhg9Ja9uoDZdS8YE.jpg", id: 9139},
    {title: "True Romance", image: "https://img.cinetimes.org/img/cache/74/1c/741c8f0c5503c3dce6946923ce97cc4b.jpg", id: 306},
    {title: "Sin City", image: "https://img.cinetimes.org/img/cache/65/47/65470f51e11b8fb25918e4325327b815.jpg", id: 4118},

]